function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _async_to_generator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
import Sentry from "@sentry/nextjs";
import { getDecodedParamFromQuery, getQueryStringAsHash } from "utils/url";
import { getCookie } from "/services/cookies-service";
import { BIG_BRAIN_VISITOR_ID, CLUSTER_COOKIE } from "/constants/cookies";
import { getEnrichmentEntriesForSales } from "services/sales-enrichment/sales-enrichment-service";
import { getPricingVersionForSales } from "services/pricing/pricing-model/services";
import { isExistingAccount } from "services/monday-platform/existing-accounts-service";
import { getClientDeviceDetails } from "services/user-agent-service";
import { CUSTOM_EVENTS, HUBSPOT_API_AB_TEST_NAME, HUBSPOT_API_CLUSTER, HUBSPOT_API_FROM, HUBSPOT_API_PRICING_VERSION, HUBSPOT_API_PRIVACY_CONSENT, HUBSPOT_API_RECENT_CONVERSION_REFERRER_PAGE, HUBSPOT_API_RECENT_CONVERSION_URL, HUBSPOT_API_SOURCE, HUBSPOT_API_UTM_CAMPAIGN, HUBSPOT_API_UTM_CONTENT, HUBSPOT_API_UTM_MEDIUM, HUBSPOT_API_UTM_SOURCE, HUBSPOT_API_UTM_TERM, HUBSPOT_API_VISITOR, URL_PARAM_FROM, WEBSITE_BOT_HELP } from "./hubspot-constants";
const HUBSPOT_FORM_URL = "https://api.hsforms.com/submissions/v3/integration/submit";
const HUBSPOT_CUSTOM_EVENT_URL = "/contact-sales/hs-event";
export const submitHubspotForm = function() {
    var _ref = _async_to_generator(function*(portalId, formId, emailValue) {
        const url = `${HUBSPOT_FORM_URL}/${portalId}/${formId}`;
        const utmParams = getUtmParams();
        const data = {
            fields: [
                {
                    name: "email",
                    value: emailValue
                }
            ].concat(utmParams)
        };
        try {
            const serverResponse = yield fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            });
            if (serverResponse.status !== 200) {
                throw new Error(serverResponse.statusText);
            }
            yield serverResponse.json();
            return true;
        } catch (err) {
            console.log("err", err);
            return false;
        }
    });
    return function submitHubspotForm(portalId, formId, emailValue) {
        return _ref.apply(this, arguments);
    };
}();
const sendHubspotSubmissionEvent = function() {
    var _ref = _async_to_generator(function*() {
        const properties = {
            is_existing_account: !!isExistingAccount()
        };
        yield sendHubspotCustomEvent(CUSTOM_EVENTS.FORM_SUBMISSION, properties);
    });
    return function sendHubspotSubmissionEvent() {
        return _ref.apply(this, arguments);
    };
}();
const sendHubspotCustomEvent = function() {
    var _ref = _async_to_generator(function*(eventName, properties) {
        const defaultProperties = getEventDefaultProperties();
        const eventProperties = _object_spread({}, defaultProperties, properties);
        const requestBody = JSON.stringify({
            eventName,
            properties: eventProperties
        });
        try {
            const response = yield fetch(HUBSPOT_CUSTOM_EVENT_URL, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: requestBody
            });
            if (response.status !== 200) {
                throw new Error(`status: ${response.status} message: ${response.statusText}`);
            }
        } catch (error) {
            Sentry.captureException(error);
            Sentry.captureMessage(`Failed to send hubspot custom event. request body: ${requestBody}, error: ${error === null || error === void 0 ? void 0 : error.message}`);
        }
    });
    return function sendHubspotCustomEvent(eventName, properties) {
        return _ref.apply(this, arguments);
    };
}();
const getUtmParams = ()=>{
    const url = window.location;
    const queryString = getQueryStringAsHash(url);
    const utmParams = [];
    const utm_source = queryString.marketing_source || queryString.utm_source;
    if (utm_source) utmParams.push({
        name: "utm_source",
        value: utm_source
    });
    const utm_campaign = queryString.marketing_campaign || queryString.utm_campaign;
    if (utm_campaign) utmParams.push({
        name: "utm_campaign",
        value: utm_campaign
    });
    const utm_banner = queryString.marketing_banner || queryString.utm_banner;
    if (utm_banner) utmParams.push({
        name: "utm_banner",
        value: utm_banner
    });
    const utm_content = queryString.marketing_content || queryString.utm_content;
    if (utm_content) utmParams.push({
        name: "utm_content",
        value: utm_content
    });
    const utm_medium = queryString.marketing_medium || queryString.utm_medium;
    if (utm_medium) utmParams.push({
        name: "utm_medium",
        value: utm_medium
    });
    const utm_term = queryString.marketing_term || queryString.utm_term;
    if (utm_term) utmParams.push({
        name: "utm_term",
        value: utm_term
    });
    const utm_cluster_id = getCookie(CLUSTER_COOKIE);
    if (utm_cluster_id) utmParams.push({
        name: "utm_cluster_id",
        value: utm_cluster_id
    });
    return utmParams;
};
export const getSingleUrlParamToHubspotForm = (location, urlParamName, hubspotApiName)=>{
    return {
        name: hubspotApiName,
        value: getDecodedParamFromQuery(location, urlParamName)
    };
};
export const sendHubspotForm = function() {
    var _ref = _async_to_generator(function*(portalId, formId, fields, sendHsEvent = true) {
        if (!portalId || !formId) return;
        const HUBSPOT_ENDPOINT = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`;
        const hubspotCookie = getCookie("hubspotutk");
        const submissionRequest = fetch(HUBSPOT_ENDPOINT, {
            method: "POST",
            body: JSON.stringify({
                fields,
                context: {
                    hutk: hubspotCookie
                }
            }),
            headers: {
                "content-type": "application/json"
            }
        });
        if (sendHsEvent) {
            const [formSubmissionResult] = yield Promise.allSettled([
                submissionRequest,
                sendHubspotSubmissionEvent()
            ]);
            if (formSubmissionResult.status !== "fulfilled") {
                throw formSubmissionResult.reason;
            }
            return formSubmissionResult.value.json();
        }
        return yield submissionRequest.json();
    });
    return function sendHubspotForm(portalId, formId, fields) {
        return _ref.apply(this, arguments);
    };
}();
export const generateHiddenData = ({ withPrivacyNotice, source, overrideCampaignId, abTests, cookies, cloudfrontViewerCountry, referrePage, bookAMeetingTestName, adaChatbotTest })=>{
    var _window_location, _window;
    const UTM_CAMPAIGN_PARAM_NAME = "utm_campaign";
    let urlParams = getEnrichmentEntriesForSales();
    if (overrideCampaignId) {
        const urlParamsWithoutCampaign = urlParams.filter((param)=>(param === null || param === void 0 ? void 0 : param.name) !== UTM_CAMPAIGN_PARAM_NAME);
        urlParamsWithoutCampaign.push({
            name: UTM_CAMPAIGN_PARAM_NAME,
            value: overrideCampaignId
        });
        urlParams = urlParamsWithoutCampaign;
    }
    const hiddenData = [
        ...urlParams
    ];
    const visitorId = getCookie(BIG_BRAIN_VISITOR_ID) || "";
    hiddenData.push({
        name: HUBSPOT_API_VISITOR,
        value: visitorId
    });
    const pricingVersion = getPricingVersionForSales({
        cookies,
        abTests,
        countryCode: cloudfrontViewerCountry
    }) || "";
    hiddenData.push({
        name: HUBSPOT_API_PRICING_VERSION,
        value: pricingVersion
    });
    const clusterId = getCookie(CLUSTER_COOKIE) || "";
    hiddenData.push({
        name: HUBSPOT_API_CLUSTER,
        value: clusterId
    });
    hiddenData.push({
        name: HUBSPOT_API_SOURCE,
        value: source
    });
    hiddenData.push({
        name: HUBSPOT_API_PRIVACY_CONSENT,
        value: withPrivacyNotice
    });
    hiddenData.push({
        name: HUBSPOT_API_RECENT_CONVERSION_URL,
        value: (_window = window) === null || _window === void 0 ? void 0 : (_window_location = _window.location) === null || _window_location === void 0 ? void 0 : _window_location.href
    });
    if (referrePage) {
        hiddenData.push({
            name: HUBSPOT_API_RECENT_CONVERSION_REFERRER_PAGE,
            value: referrePage
        });
    }
    if (bookAMeetingTestName) {
        hiddenData.push({
            name: HUBSPOT_API_AB_TEST_NAME,
            value: bookAMeetingTestName
        });
    }
    if (adaChatbotTest) {
        hiddenData.push({
            name: WEBSITE_BOT_HELP,
            value: true
        });
    }
    const fromUrlParamObject = getSingleUrlParamToHubspotForm(window.location, URL_PARAM_FROM, HUBSPOT_API_FROM);
    if (fromUrlParamObject.value) hiddenData.push(fromUrlParamObject);
    return hiddenData;
};
const getEventDefaultProperties = ()=>{
    var _JSON_parse, _JSON_parse1, _JSON_parse2, _JSON_parse3, _JSON_parse4;
    const userAgentAnalytics = getClientDeviceDetails();
    return {
        hs_referrer: document.referrer,
        hs_page_url: window.location.href,
        hs_page_title: document.title,
        hs_utm_source: (_JSON_parse = JSON.parse(localStorage.getItem(HUBSPOT_API_UTM_SOURCE))) === null || _JSON_parse === void 0 ? void 0 : _JSON_parse.value,
        hs_utm_medium: (_JSON_parse1 = JSON.parse(localStorage.getItem(HUBSPOT_API_UTM_MEDIUM))) === null || _JSON_parse1 === void 0 ? void 0 : _JSON_parse1.value,
        hs_utm_campaign: (_JSON_parse2 = JSON.parse(localStorage.getItem(HUBSPOT_API_UTM_CAMPAIGN))) === null || _JSON_parse2 === void 0 ? void 0 : _JSON_parse2.value,
        hs_utm_term: (_JSON_parse3 = JSON.parse(localStorage.getItem(HUBSPOT_API_UTM_TERM))) === null || _JSON_parse3 === void 0 ? void 0 : _JSON_parse3.value,
        hs_utm_content: (_JSON_parse4 = JSON.parse(localStorage.getItem(HUBSPOT_API_UTM_CONTENT))) === null || _JSON_parse4 === void 0 ? void 0 : _JSON_parse4.value,
        hs_browser: userAgentAnalytics.browser,
        hs_device_type: userAgentAnalytics.device_type,
        hs_device: userAgentAnalytics.device,
        hs_operating_system: userAgentAnalytics.operating_system,
        hs_operating_version: userAgentAnalytics.operating_version
    };
};
